<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      title="修改联系方式"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="500px"
    >

<a-form
        :form="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >

        <a-form-item label="联系方式">
        <a-input
          v-decorator="formDecorators.contact_way"
        />
      </a-form-item>

      <!-- <a-form-item label="入会情况">
        <a-radio-group v-decorator="formDecorators.initiate_status" >
          <a-radio :value="2">
            未入会
          </a-radio>
          <a-radio :value="1">
            已入会
          </a-radio>
        </a-radio-group>
      </a-form-item> -->
      </a-form>
    </a-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      menuList:[],
      list:[]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
    menus: {
      type: Array,
      default:()=>{
        return []
      }
    },
  },
  computed: {
    formDecorators() {
      let data = JSON.parse(JSON.stringify(this.row));

      return {
        contact_way: [
          "contact_way",
          {
            initialValue: data && data.contact_way,
            rules: [{ required: true, message: "请填写联系方式" }],
            validateTrigger: ["blur"],
          },
        ],
        // initiate_status: [
        //   "initiate_status",
        //   {
        //     initialValue: data && data.initiate_status
        //   },
        // ]
      };
    },
  },
  created(){
    
  },
  mounted() {
    
  },
  filters:{

  },
  methods: {

    // 图标选择
    handleIconChange (icon) {
      this.icon = icon
      let fieldsValue = {
        icons: icon
      }
      this.form.setFieldsValue(fieldsValue)
    },

    handleOk(){
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id=this.row.id
          this.$api.post(`/v2/anchorDataGame/upd`, values)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("操作成功");
                this.$emit("handleOk");
              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        }else{
          this.confirmLoading = false;
        }
        })
    },
    handleCancel(){
      this.$emit('handleCancel')
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
