<template>
  <div class="dashboard-table-card-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <template>
        <div class="container">
          <div class="card-title">
            素人主播
            <div class="num-box">{{ pageOptions.total }}人</div>
          </div>
          <!--pc-->
          <div v-if="!isMobile">
            <a-row class="select-where">
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">主播类型：</div>
                <a-select
                    default-value="0"
                    v-model="signing_status"
                    style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 有公会</a-select-option>
                  <a-select-option :value="2"> 无公会</a-select-option>
                  <a-select-option :value="3"> 未知</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">是否繁星：</div>
                <a-select
                    default-value="2"
                    v-model="is_stars"
                    style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">跟进：</div>
                <a-select
                    default-value="0"
                    v-model="follow_up"
                    style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="-1"> 无跟进</a-select-option>
                  <a-select-option :value="1"> 我跟进的</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">舰长数：</div>
                <a-select v-model="guard_type" style="width: 200px">
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 0~3 舰长</a-select-option>
                  <a-select-option :value="2"> 4~10 舰长</a-select-option>
                  <a-select-option :value="3"> 11~30 舰长</a-select-option>
                  <a-select-option :value="4"> 30 舰长以上</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <a-row style="margin: 0 30px">
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">新人：</div>
                <a-select
                    default-value="0"
                    v-model="is_new_anchor"
                    style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">主播昵称：</div>
                <a-input
                    :min="1"
                    v-model="name"
                    placeholder="输入主播昵称"
                    style="width: 200px"
                ></a-input>
              </a-col>
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 68px">主播UID：</div>
                <a-input
                    :min="1"
                    v-model="uid"
                    placeholder="输入主播UID"
                    style="width: 200px"
                ></a-input>
              </a-col>
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 86px">房间号：</div>
                <a-input
                    :min="1"
                    v-model="room_id"
                    placeholder="输入房间号"
                    style="width: 200px"
                ></a-input>
              </a-col>
            </a-row>
            <a-row style="margin: 30px">
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">一级分区：</div>
                <a-select
                    default-value="0"
                    v-model="area_1"
                    style="width: 200px"
                >
                  <a-select-option v-for="(v,i) in areaData1" :key="i" :value="v['id']">
                    {{ v['area_name'] }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">二级分区：</div>
                <a-select
                    default-value="0"
                    v-model="area_2"
                    style="width: 200px"
                >
                  <a-select-option v-for="(v,i) in areaData2" :key="i" :value="v['id']">
                    {{ v['area_name'] }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">是否开播：</div>
                <a-select
                    default-value="0"
                    v-model="live_status"
                    style="width: 200px"
                    :disabled="!area_2"
                >
                  <a-select-option :value="-1"> 全部</a-select-option>
                  <a-select-option :value="1"> 直播中</a-select-option>
                  <a-select-option :value="0"> 未开播</a-select-option>
                </a-select>
              </a-col>

            </a-row>
            <a-row style="margin: 30px">
              <a-col
                  :span="6"
                  style="display: flex; align-items: center; height: 32px"
              >
                <le-button
                    txt="查询"
                    width="40px"
                    height="25px"
                    fontSize="14px"
                    style="margin-right: 10px"
                    @btnClick="searchData()"
                />
                <le-button
                    txt="重置"
                    width="40px"
                    height="25px"
                    fontSize="14px"
                    background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
                    @btnClick="resetGetData()"
                />
                <le-button
                    :loading="isLoad"
                    v-if="btn.isExportAll"
                    txt="导出当前查询记录"
                    width="120px"
                    height="25px"
                    fontSize="14px"
                    style="margin-left: 10px"
                    @btnClick="
                  modalText = '最多导出前10000条数据';
                  exprotNum = 10000;
                  exShowModal();
                "
                />
              </a-col>
              <a-col
                  :span="3"
                  style="display: flex; align-items: center; height: 32px"
                  v-if="btn.isExport"
              >
                导出前
                <a-input-number
                    v-model="value"
                    :min="1"
                    :max="10000"
                    size="small"
                />
                条
                <le-button
                    txt="导出"
                    width="40px"
                    height="25px"
                    fontSize="14px"
                    style="margin-left: 10px"
                    @btnClick="
                  exportVisible = true;
                  modalText = `导出前${value}条数据`;
                  exprotNum = value;
                "
                />
              </a-col>
            </a-row>
          </div>
          <!--手机-->
          <div v-if="isMobile">
            <a-row class="select-where">
              <a-col :span="12" class="select-col">
                <div class="select-tit">主播类型：</div>
                <a-select
                    default-value="0"
                    v-model="signing_status"
                    style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 有公会</a-select-option>
                  <a-select-option :value="2"> 无公会</a-select-option>
                  <a-select-option :value="3"> 未知</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="12" class="select-col">
                <div class="select-tit">是否繁星123：</div>
                <a-select
                    :default-value="2"
                    v-model="is_stars"
                    style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <a-row class="select-where">
              <!--              <a-col :span="12" class="select-col">-->
              <!--                <div class="select-tit">跟进：</div>-->
              <!--                <a-select-->
              <!--                        default-value="0"-->
              <!--                        v-model="follow_up"-->
              <!--                        style="width: 200px"-->
              <!--                >-->
              <!--                  <a-select-option :value="0"> 全部</a-select-option>-->
              <!--                  <a-select-option :value="-1"> 无跟进</a-select-option>-->
              <!--                  <a-select-option :value="1"> 我跟进的</a-select-option>-->
              <!--                </a-select>-->
              <!--              </a-col>-->
              <a-col :span="12" class="select-col">
                <div class="select-tit">新人：</div>
                <a-select
                    default-value="0"
                    v-model="is_new_anchor"
                    style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="12" class="select-col">
                <div class="select-tit">舰长数：</div>
                <a-select v-model="guard_type" style="width: 200px">
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 0~3 舰长</a-select-option>
                  <a-select-option :value="2"> 4~10 舰长</a-select-option>
                  <a-select-option :value="3"> 11~30 舰长</a-select-option>
                  <a-select-option :value="4"> 30 舰长以上</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <a-row class="select-where">
              <a-col :span="12" class="select-col">
                <div style="width: 68px">UID：</div>
                <a-input
                    :min="1"
                    v-model="uid"
                    placeholder="输入UID"
                ></a-input>
              </a-col>
              <a-col :span="12" class="select-col">
                <div style="width: 86px">房间号：</div>
                <a-input
                    :min="1"
                    v-model="room_id"
                    placeholder="输入房间号"
                ></a-input>
              </a-col>
            </a-row>
            <a-row>
              <a-col
                  :span="24"
                  style="display: flex; align-items: center; height: 32px;justify-content: center;"
              >
                <le-button
                    txt="查询"
                    width="40px"
                    height="25px"
                    fontSize="14px"
                    style="margin-right: 10px"
                    @btnClick="searchData()"
                />
                <le-button
                    txt="重置"
                    width="40px"
                    height="25px"
                    fontSize="14px"
                    background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
                    @btnClick="resetGetData()"
                />
              </a-col>
            </a-row>
          </div>

          <div class="table-box">
            <!--pc-->
            <a-table
                :columns="columns"
                :data-source="dataSource"
                :loading="loading"
                :pagination="pageOptions"
                @change="handleTableChange"
                size="middle"
                v-if="!isMobile"
            >
              <div slot="uname" slot-scope="row">
                <a
                    class="name-box"
                    :href="'https://space.bilibili.com/' + row.bili_id"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <div class="avatar">
                    <img :src="row.face" alt="" srcset=""/>
                  </div>
                  <div class="name-uid">
                    <div>{{ row.uname }}</div>
                    <div>uid:{{ row.bili_id }}</div>
                  </div>
                </a>
              </div>

              <div slot="is_sign" slot-scope="row">
                <div style="color: #ff6363" v-if="row.signing_status == 1">
                  已签约
                </div>
                <div style="color: #4acc7f" v-else-if="row.signing_status == 2">
                  未签约
                </div>
                <div style="color: #ff6363" v-else-if="row.signing_status == 3">
                  鹿角科技
                </div>
                <div style="color: #333" v-else>--</div>
              </div>
              <div slot="is_new_anchor" slot-scope="row">
                <div>{{ row.is_new_anchor == 1 ? "是" : "否" }}</div>
              </div>
              <div slot="is_stars" slot-scope="row">
                <div>{{ row.is_stars == 1 ? "是" : "否" }}</div>
              </div>
              <div slot="is_live" slot-scope="row">
                <a target="_blank" :href="'https://live.bilibili.com/' + row.roomid"
                   @click="handleLiveRoomClick(row.bili_id,row.uname)">
                  <div v-if="row.live_status == 1" style="color: #cf1322">直播中...</div>
                  <div v-else style="color: #595959">未开播</div>
                </a>
              </div>
              <span slot="action" slot-scope="row">
                <a
                    @click="
                    visible = true;
                    rows = row;
                  "
                >详情</a>
                <a-divider type="vertical"/>
                <a
                    target="_blank"
                    :href="'https://live.bilibili.com/' + row.roomid"
                    @click="handleLiveRoomClick(row.bili_id,row.uname)"
                >直播间</a>
                <a-divider type="vertical"/>
                <span
                    style="color: #ddd"
                    v-if="row.root_into_admin_id && info.uid != row.root_into_admin_id">已跟进</span>
                <a
                    @click="rootInto(row.id)"
                    v-else-if="row.root_into_admin_id"
                >取消跟进</a
                >
                <a @click="rootInto(row.id)" v-else>跟进</a>
                <a-divider type="vertical"/>
                <a @click="renovate(row.id)">更新</a>
                <a-divider type="vertical"/>
                 <a @click="modalShow=true;modalShowData=row">备注</a>

                <!--                <a-divider type="vertical"/>-->

                <!--                <a-popconfirm-->
                <!--                        title="确定入会吗？"-->
                <!--                        ok-text="确定"-->
                <!--                        cancel-text="取消"-->
                <!--                        @confirm="upd(row.bili_id, 1)"-->
                <!--                        v-if="row.initiate_status == 2"-->
                <!--                >-->
                <!--                  <a>入会</a>-->
                <!--                </a-popconfirm>-->

                <!--                <a-popconfirm-->
                <!--                        title="确定退会吗？"-->
                <!--                        ok-text="确定"-->
                <!--                        cancel-text="取消"-->
                <!--                        @confirm="upd(row.bili_id, 2)"-->
                <!--                        v-if="row.initiate_status == 1"-->
                <!--                >-->
                <!--                  <a>退会</a>-->
                <!--                </a-popconfirm>-->
              </span>
            </a-table>
            <!--手机-->
            <a-table
                :columns="columnsM"
                :data-source="dataSource"
                :loading="loading"
                :pagination="pageOptions"
                @change="handleTableChange"
                size="middle"
                v-if="isMobile"
            >
              <div slot="uname" slot-scope="row">
                <div
                    class="name-box"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  <div class="avatar">
                    <img :src="row.face" alt="" srcset=""/>
                    <div v-if="row.signing_status === 1" style="color: #e51212">已签</div>
                    <div v-else-if="row.signing_status === 2" style="color: green">未签</div>
                    <div v-else-if="row.signing_status === 3" style="color: #222222">鹿角</div>
                    <div v-else style="color: #e9b70c">未知</div>
                  </div>
                  <div class="name-uid">
                    <a :href="'https://space.bilibili.com/' + row.bili_id">{{ row.uname }}</a>
                    <div @click="copyBiliId(row.bili_id)">
                      <div>uid:{{ row.bili_id }}</div>
                      <div>舰长数:{{ row.guard_num }}</div>
                      <div v-if="row.star_level_name != false">繁星:{{
                          row.star_level_name
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <span slot="action" slot-scope="row">
                                <a @click="visible = true;rows = row;">详情</a>
                                <a-divider type="vertical"/>
                                <a target="_blank" :href="'https://live.bilibili.com/' + row.roomid"
                                   @click="handleLiveRoomClick(row.bili_id,row.uname)">直播间</a>
                                <a-divider type="vertical"/>
                                <a @click="renovate(row.id)">更新</a>
                              </span>
            </a-table>
          </div>
        </div>
      </template>
    </le-card>
    <popEdit
        v-if="visible"
        :visible="visible"
        :item="rows"
        @handleOk="visible = false"
        @handleCancel="visible = false"
    />
    <popContact
        v-if="cvis"
        :visible="cvis"
        :row="rows"
        @handleOk="
        cvis = false;
        getData();
      "
        @handleCancel="cvis = false"
    />
    <popRemark
        v-if="rvis"
        :visible="rvis"
        :row="rows"
        :dataSource="rows.remarks"
        @handleOk="
        rvis = false;
        getData();
      "
        @handleCancel="rvis = false"
    />

    <a-modal
        title="设置备注"
        :visible="modalShow"
        :confirm-loading="confirmLoading"
        okText="保存"
        @ok="setDesc()"
        @cancel="modalShow = false"
        :maskClosable="false"
        :closable="false"
    >
      <a-textarea
          v-model="modalShowValue"
          placeholder="设置备注"
          :auto-size="{ minRows: 5, maxRows: 65 }"
      />
    </a-modal>
  </div>
</template>

<script>
import popEdit from "./popEdit";
import popContact from "./popContact";
import popRemark from "./popRemark";
import {MixinList} from "@/common/mixin";
import {Mx} from "./mixin";

const queryMapper = {
  keyword: 'name',
  first_area: 'area_1',
  second_area: 'area_2',
}

export default {
  components: {
    popEdit,
    popContact,
    popRemark,
  },
  data() {
    return {
      dataUrl: "v2/anchorDataGame/sel",
      delUrl: "admin/layout/del",
      isMobile: false,
      modalShow: false,
      modalShowData: null,
      modalShowValue: null,
      areaData1: [],
      areaData2: [],
    };
  },
  created() {
    this.queryParam.signing_status = 2;

    this.queryParam.is_stars = 2;
    this.is_stars = 2;
  },
  computed: {},
  watch: {
    area_1(newVal) {
      if (newVal) {
        this.getAreaData(newVal)
        this.area_2 = '';
        this.live_status = -1;
      }
    },
  },
  beforeMount() {
    this.parseParams();
  },
  mounted() {
    if (
        navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
    ) {
      this.isMobile = true;
    }
    this.getAreaData(0)
  },
  mixins: [MixinList, Mx],
  methods: {
    setDesc() {
      this.$api.get(`/v2/anchorDataGame/upd?id=${this.modalShowData.id}&contact_way=${this.modalShowValue}&initiate_status=${this.modalShowData.initiate_status}`).then((res) => {
        this.modalShow = false
      });
    },
    // 请求分区数据
    getAreaData(pid) {
      this.$api.post(`v2/area/get`, {pid}).then((res) => {
        if (res.code === 200) {
          if (pid == 0) {
            this.areaData1 = res.data
          } else {
            this.areaData2 = res.data
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    copyBiliId(id) {
      let oInput = document.createElement("input");
      oInput.value = id;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success("复制成功！");
      oInput.remove();
    },
    // 退会入会操作
    upd(bili_id) {
      this.$api
          .post(`v2/anchor/enterGuild`, {bili_id, type: 1})
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("更新成功");
              this.getData();
            } else {
              this.$message.error(res.message);
            }
          });
    },

    // 刷新主播信息
    renovate(id) {
      this.$api.post(`v2/anchorDataGame/renovate`, {id}).then((res) => {
        if (res.code == 200) {
          this.$message.success("更新成功");
          if (res.data.id) {
            const f = this.dataSource.find(item => item.id == res.data.id)
            if (f) Object.assign(f, res.data)
          }
          // this.getData();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 游戏主播跟进退跟
    rootInto(id) {
      this.$api.post(`v2/anchorDataGame/rootInto`, {id}).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.getData();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 直播间点击记录
    handleLiveRoomClick(uid, uname) {
      this.$api.post('/v2/anchorDataGame/goStudio', {
        uid,
        uname,
      });
    },

    parseParams() {
      let {query} = this.$route.params;
      if (query) {
        this.resetData();
        for (let k in query) {
          let v = query[k];
          if (typeof this[queryMapper[k] || k] == 'number') v = parseInt(v);
          this[queryMapper[k] || k] = v;
          this.queryParam[k] = v;
        }
        return true;
      }
      return false;
    }
  },
};
</script>

<style lang="less" scoped>
.hidden {
  display: none;
}

.dashboard-table-card-container {
  .container {
    position: relative;

    .num-box {
      font-size: 14px;
      margin-left: 11px;
      line-height: 32px;
    }

    .table-box {
      margin: 40px 30px;
    }

    .card-title {
      display: flex
    }

    .select-where {
      margin: 30px;

      .select-col {
        display: flex;
        align-items: center;

        .select-tit {
          width: 70px
        }
      }


    }
  }
}

.btn-canle {
  width: 70px;
  height: 30px;
  background: linear-gradient(-10deg, #fd8a8f, #fa6a9d);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
  margin-left: 10px;
}

.login {
  cursor: pointer;

  &:hover {
    color: #008df0;
  }
}

.name-box {
  display: flex;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
  .dashboard-table-card-container {
    .container {
      .num-box {
        font-size: 12px;
        margin-left: 5px;
        line-height: 22px;
      }

      .table-box {
        margin: 20px;
        overflow-x: scroll;
      }

      .select-where {
        margin: 10px;

        .select-col {
          display: flex;
          align-items: center;

          .select-tit {
            width: 150px
          }
        }


      }
    }
  }
}
</style>
