<template>
    <div class="dashboard-search-card-container ">
      <le-card width="100%" height="150px" >
        <template>
          <div class="container">
            <div class="title">搜索</div>
            <div class="search-box">
              <div class="time-box">
              <div class="time-title">主播UID查询：</div>
              <a-input v-model="search" placeholder="请输入主播UID" class="input-search" :maxLength="20" :allowClear="true" @change="changeSearch()" />
              </div>

              <div class="button-box">
                  <le-button  @btnClick="searchClick"  class="btn-search"  />
              </div>
            </div>
          </div>
        </template>
      </le-card>
    </div>
</template>

<script>
import tool from '@/utils/tool'
export default {
  components: { 
  },
  data() {
    return {
      search:''
    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {
    searchClick(){
      console.log(this.search)
      if(!this.search){
        this.$message.error('请填入主播UID');
        return false
      }

      tool.$emit('searchOut', this.search)
    },
    changeSearch(){
      console.log('123')
      if(!this.search){
        tool.$emit('clearOut')
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
.container{
  position: relative;
  .title{
    padding-top: 20px;
    margin-left: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .search-box{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .time-box{
      display: flex;
      align-items: center;
      .time-title{
        margin-left: 30px;
        font-size: 24px;
        color: #333333;
      }
      .input-search{
        width:200px;
        margin-left:10px
      }
    }
    .button-box{
      margin-right: 30px;
    }
  }
}
}


@media screen and(max-width: 750px) {
.dashboard-search-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 20px;
  }
  .search-box{
    margin-top: 10px;
    .time-box{
      .time-title{
        margin-left: 20px;
        font-size: 16px;
        width: 110px;
      }
      .input-search{
        width:150px;
      }
    }
    .button-box{
      // margin-right: 30px;
      margin-left: 10px;
      .btn-search{
        width: 50px!important;
      }
    }
  }
}
}
}

</style>
