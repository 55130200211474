const columnsM = [
    {
        title: "昵称/UID",
        scopedSlots: {customRender: "uname"},
        width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 300 : 150,
    },
    {
        title: "操作",
        width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 150 : 280,
        key: "action",
        scopedSlots: {customRender: "action"},
        fixed: "right",
    },
];

import ExportJsonExcel from 'js-export-excel';

const Mx = {
    data() {
        return {
            columns: [],
            columnsM,
            visible: false,
            isLoad: false,
            cvis: false,
            rvis: false,
            exportVisible: false,
            confirmLoading: false,
            modalText: '最多导出前10000条数据',
            exprotNum: 1,
            type: 'uname',
            rows: {},
            fleet: 0,
            value: 1,
            signing_status: 2,
            is_stars: 0,
            follow_up: 0,
            is_new_anchor: 0,
            guard_type: 0,
            keyword: '',
            uid: '',
            room_id: '',
            name: '',
            area_1: '',
            area_2: '',
            live_status: -1,
            info: {},
            btn: {
                isExport: false,
                isExportAll: false
            }

        }
    },
    components: {},
    beforeMount() {
        this.queryParam.is_sign = 0;
        this.refreshColumns();
    },
    mounted() {
        this.info = JSON.parse(window.localStorage.getItem("info"))
        let btn = window.localStorage.getItem('whiteBtns')
        if (btn) {
            let btns = btn.split(',');
            if (btns.includes('anchor-manager-plain-export')) {
                this.btn.isExport = true;
            }
            if (btns.includes('anchor-manager-plain-exporta')) {
                this.btn.isExportAll = true;
            }
        }
    },
    methods: {
        showRemark(row) {
            this.rvis = true;
            this.rows = row
            this.rows.type = 2
            if (!row.root_into_admin_id) {
                this.rootInto(row.id)
            }

            if (this.rows.remarks.length > 0) {
                this.rows.value = this.rows.remarks[0].remarks
            } else {
                this.rows.value = '无'
            }
        },
        exShowModal() {
            if (this.pageOptions.total > 10000) {
                this.exportVisible = true;
            } else {
                this.isLoad = true
                this.exListExcel()
            }
        },

        //导出前几条数据
        exportExcel() {
            let arr1 = JSON.parse(JSON.stringify(this.dataSource))
            let arr2 = arr1.splice(0, this.value)
            this.downloadExcel(arr2)
        },

        // 导出excle
        exListExcel() {
            this.confirmLoading = true
            let params = JSON.parse(JSON.stringify(this.queryParam));
            params.page = 1

            params.perPage = this.exprotNum
            this.$api.get(this.dataUrl, {params}).then((res) => {
                this.isLoad = false
                if (res.code == 200) {
                    this.downloadExcel(res.data.list)
                } else {
                    this.$message.error(res.message)
                    this.confirmLoading = false
                }
            })
        },

        // 导出Excel
        downloadExcel(datas) {
            var option = {};
            let dataTable = [];
            if (datas) {
                for (let i in datas) {
                    let obj = {
                        'ID': datas[i].id,
                        '昵称': datas[i].uname,
                        'uid': datas[i].bili_id,
                        '房间号': datas[i].roomid,
                        '签约状态': datas[i].signing_status == 2 ? '未签约' : '已签约',
                        '是否是新人': datas[i].is_new_anchor == 2 ? '否' : '是',
                        '是否是繁星': datas[i].is_stars == 2 ? '否' : '是',
                        '跟进人': datas[i].root_into_uname,
                        '联系方式': datas[i].contact_way,
                        '最后开播时间': datas[i].last_broadcast_time,
                        '有效天': datas[i].effective_day
                    }
                    dataTable.push(obj);
                }
            }
            option.fileName = '游戏素人主播列表'
            option.datas = [
                {
                    sheetData: dataTable,
                    sheetName: 'sheet',
                    sheetFilter: ['ID', '昵称', 'uid', '签约状态', '房间号', '是否是新人', '是否是繁星', '跟进人', '联系方式', '最后开播时间', '有效天'],
                    sheetHeader: ['ID', '昵称', 'uid', '签约状态', '房间号', '是否是新人', '是否是繁星', '跟进人', '联系方式', '最后开播时间', '有效天'],
                }
            ];
            var toExcel = new ExportJsonExcel(option);
            toExcel.saveExcel();
            this.confirmLoading = false
            this.exportVisible = false
            this.isLoad = false
        },

        // 搜索数据
        searchData() {
            this.queryParam.page = 1;
            console.log('signing_status', this.signing_status);
            if (this.signing_status == 0) {
                delete this.queryParam.signing_status
            } else {
                this.queryParam.signing_status = this.signing_status;
            }

            if (this.is_stars == 0) {
                delete this.queryParam.is_stars
            } else {
                this.queryParam.is_stars = this.is_stars
            }

            if (this.follow_up == 0) {
                delete this.queryParam.follow_up
            } else {
                this.queryParam.follow_up = this.follow_up
            }

            if (this.is_new_anchor == 0) {
                delete this.queryParam.is_new_anchor
            } else {
                this.queryParam.is_new_anchor = this.is_new_anchor
            }

            if (this.live_status == -1 || this.area_2 === '') {
                delete this.queryParam.live_status
            } else {
                this.queryParam.live_status = this.live_status
            }

            if (this.guard_type == 0) {
                delete this.queryParam.guard_type
            } else {
                this.queryParam.guard_type = this.guard_type
            }

            if (this.keyword === '') {
                delete this.queryParam.keyword
            } else {
                this.queryParam.keyword = this.keyword
            }

            if (this.uid === '') {
                delete this.queryParam.uid
            } else {
                this.queryParam.uid = this.uid
            }
            if (this.room_id === '') {
                delete this.queryParam.room_id
            } else {
                this.queryParam.room_id = this.room_id
            }
            if (this.name === '') {
                delete this.queryParam.keyword
            } else {
                this.queryParam.keyword = this.name
            }
            if (this.area_1 === '') {
                delete this.queryParam.first_area
            } else {
                this.queryParam.first_area = this.area_1
            }
            if (this.area_2 === '') {
                delete this.queryParam.second_area
            } else {
                this.queryParam.second_area = this.area_2
            }
            console.log(this.queryParam)
            this.getData();
        },


        // 重设数据
        resetGetData() {
            this.resetData();
            this.getData();
        },
        resetData() {
            let perPage = this.queryParam.perPage
            this.queryParam = {}
            this.queryParam.page = 1;
            this.queryParam.perPage = perPage;

            this.fleet = 0
            this.value = 0
            this.signing_status = 0
            this.is_stars = 0
            this.follow_up = 0
            this.is_new_anchor = 0
            this.guard_type = 0
            this.name = ''
            this.area_1 = ''
            this.area_2 = ''
            this.uid = ''
            this.room_id = '';
        },

        //搜索
        onSearch(value) {
            if (!value) {
                delete this.queryParam.keyword
                delete this.queryParam.search_by
            } else {
                this.queryParam.keyword = value
                this.queryParam.search_by = this.type
            }
            this.queryParam.page = 1;
            console.log(this.queryParam);
            this.getData();
        },

        changeTab(index) {
            this.queryParam.is_sign = index;
            this.queryParam.page = 1;
            this.getData();
        },
        handleOk() {
            this.visible = false;
            this.getData();
        },
        handleCancel() {
            this.visible = false;
            this.getData();
        },
        refreshColumns() {
            this.columns = [
                {
                    title: "昵称/UID",
                    scopedSlots: {customRender: "uname"},
                    width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 300 : 150,
                },
                {
                    title: "签约状态",
                    width: 75,
                    scopedSlots: {customRender: "is_sign"},
                },
                {
                    title: "舰长数",
                    width: 75,
                    key: 'guard_num',
                    sorter: true,
                    dataIndex: 'guard_num'
                },
                {
                    title: "繁星等级",
                    width: 75,
                    dataIndex: 'star_level_name'
                },
                {
                    title: "是否繁星",
                    width: 75,
                    scopedSlots: {customRender: "is_stars"}
                },
                {
                    title: "是否新人",
                    width: 75,
                    scopedSlots: {customRender: "is_new_anchor"}
                },
                ...(this.area_2 ? [{
                    title: "是否开播",
                    width: 75,
                    scopedSlots: {customRender: "is_live"}
                }] : []),
                {
                    title: "分区",
                    width: 75,
                    dataIndex: 'area_name'
                },
                {
                    title: "跟进人",
                    width: 75,
                    dataIndex: 'root_into_uname'
                },
                {
                    title: "大航海数量更新时间",
                    width: 100,
                    dataIndex: 'save_guard_num_time'
                },
                {
                    title: "最后开播",
                    width: 100,
                    sorter: true,
                    dataIndex: 'last_broadcast_time'
                },
                {
                    title: "操作",
                    width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 150 : 300,
                    key: "action",
                    scopedSlots: {customRender: "action"},
                    fixed: "right",
                },
            ];
        },
    },
    watch:{
        dataSource(){
            this.refreshColumns();
        }
    }
}


export {Mx}
